/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
// import NavBar from "./components/NavBar";
// import ethereum from "../src/asset/image/Ethereum_Flatline.svg";
import { useState } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { contractABI } from "./contractABI";
// import ContractSupplyCard from "./components/ContractSupplyCard";
// import BalanceCard from "./components/BalanceCard";
import Header from "./components/Header/Header";
import ChampsBalanceCard from "./components/BalanceCard/ChampsBalanceCard";
import ClaimEthCard from "./components/ClaimEthCard/ClaimEthCard";
import TotalEthReward from "./components/TotalEthReward/TotalEthReward";
import { Box, Container, Grid, Stack } from "@mui/material";
import SearchCard from "./components/SearchCard/SearchCard";
import DetailsCard from "./components/DetailsCard/DetailsCard";
import YourBalance from "./components/YourBalance/YourBalance";
import Tokenomics from "./components/Tokenomics/Tokenomics";
import toast, { Toaster } from "react-hot-toast";
import Footer from "./components/Footer/Footer";
// import Footer from "./components/Footer/Footer";

function App() {
  let [provider, setProvider] = useState(null);
  const [claimrewards, setClaimRewards] = useState(null);
  // const [showrewards, setShowRewards] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);
  const [totalRewardsDistributed, setTotalRewardsDistributed] = useState(null);
  const [ethTokenBalance, setEthTokenBalance] = useState(0);
  // useState(null);
  // const [binancePegETHPrice, setBinancePegETHPrice] = useState(1);
  // const [userWalletDividendsInfo, setUserWalletDividendsInfo] = useState(null);
  // const [balanceInfo, setBalanceInfo] = useState(0);
  const [CurrentAdd, setCurrentAdd] = useState("0X");
  const [balance, setBalance] = useState();
  // const [contractName, setContractName] = useState(null);
  const [contractSymbol, setContractSymbol] = useState(null);
  const [contractSupply, setContractSupply] = useState(null);
  // const [show, setShow] = useState(false);
  // const [Status, setStatus] = useState(null);
  const [UserWallet, setUserWallet] = useState(null);
  // const [showPopup, setShowPopup] = useState(false);

  // const smartContractAddress = "0x294E6f6427577e01A7297bD1AA32844A9494a84F";
  // const smartContractAddress = "0xCC2aA5417E53eCD892173BF91F87f7Bf5f220c37";
  const smartContractAddress = "0x3573Fc40fdFD745A0c4e8Ec76bDA07dAf7dd1864";
  const wethContractAddress = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
  // const AdminWalletAddress = "0x78243e5c0161FC99A8E0A81f0337db61A0BBa516";
  // const binancePegETHTokenAddress =
  //   "0x2170ed0880ac9a755fd29b2688956bd959f933f8";

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      display: {
        name: "WalletConnect",
        description: "",
      },
      options: {
        // rpc: {
        //   1: "https://bsc-dataseed.binance.org/",
        //   56: "https://bsc-dataseed.binance.org/",
        //   97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        // },
        rpc: {
          42161: "https://arb1.arbitrum.io/rpc/",
          // 56: "https://bsc-dataseed.binance.org/",
          // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        },
        network: "Arbitrum One",
      },
    },
  };

  const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions, // required
    // disableInjectedProvider: false, // optional, For MetaMask / Brave / Opera.
    theme: {
      background: "rgb(39, 49, 56)",
      main: "rgb(199, 199, 199)",
      secondary: "rgb(136, 136, 136)",
      border: "rgba(195, 195, 195, 0.14)",
      hover: "rgb(16, 26, 32)",
    },
  });

  async function init() {
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://rpc.tornadoeth.cash/arbitrum")
    );

    const CHAMPSContract = new web3.eth.Contract(
      contractABI,
      smartContractAddress
    );

    const WETHContract = new web3.eth.Contract(
      contractABI,
      wethContractAddress
    );

    setCurrentAdd(UserWallet);
    // console.log(1);
    // const tokenName = await CHAMPSContract.methods.name().call();
    const tokenSymbol = await CHAMPSContract.methods
      .getNumberOfDividendTokenHolders()
      .call();
    const totalSupply = await CHAMPSContract.methods.totalSupply().call();
    // setContractName(tokenName);
    // console.log(tokenName);
    setContractSymbol(Number(tokenSymbol));
    setContractSupply(totalSupply);
    const TRD = await CHAMPSContract.methods
      .getTotalDividendsDistributed()
      .call();
    setTotalRewardsDistributed(TRD);
    console.log(TRD, "Total ETH Rewards.....");
    // -------------------------------------------------
    const weth_balance = await WETHContract.methods
      .balanceOf(UserWallet)
      .call();
    setEthTokenBalance(weth_balance);
    console.log(weth_balance, "ETH Token balance");
    // -------------------------------------------------
    const balan = await CHAMPSContract.methods.balanceOf(UserWallet).call();
    // console.log(balance, "balance NEw");
    setBalance(balan);
    // console.log(balance, "balance");
    // const dividend_balance = await CHAMPSContract.methods
    //   .getAccountDividendsInfo(UserWallet)
    //   .call();
    // console.log(dividend_balance);
    // setUserWalletDividendsInfo(dividend_balance);
    let claimReward = await CHAMPSContract.methods
      .dividendTokenBalanceOf(UserWallet)
      .call();
    setClaimRewards(web3.utils.fromWei(claimReward));
    // showRewards
    console.log(web3.utils.fromWei(claimReward), "Claimable ETH Rewards.....");
    // let showRewards = await CHAMPSContract.methods
    //   .withdrawableDividendOf(UserWallet)
    //   .call();
    // // setshowRewards(web3.utils.fromWei(showRewards));
    // setShowRewards(web3.utils.fromWei(showRewards));
    // console.log(claimrewards, showRewards, "showRewards");
    // console.log(tokenName, Symbol, "Symbol");
  }

  async function refreshAccountData() {
    // console.log("refreshAccountData");
    await fetchAccountData(provider);
  }

  async function Disconnect() {
    if (provider.close) {
      await provider.close();

      await web3Modal.clearCachedProvider();
      setProvider(null);
    }
    setWalletConnected(false);
    // setUserWalletDividendsInfo(0);
  }

  async function fetchAccountData() {
    let web3 = new Web3(provider);
    const chainId = await web3.eth.getChainId();
    // console.log(chainId);
    try {
      if (chainId === 42161) {
        const CHAMPSContract = new web3.eth.Contract(
          contractABI,
          smartContractAddress
        );

        const WETHContract = new web3.eth.Contract(
          contractABI,
          wethContractAddress
        );

        // const tokenName = await CHAMPSContract.methods.name().call();
        const tokenSymbol = await CHAMPSContract.methods
          .getNumberOfDividendTokenHolders()
          .call();
        const totalSupply = await CHAMPSContract.methods.totalSupply().call();
        // setContractName(tokenName);
        // console.log(tokenName);
        setContractSymbol(Number(tokenSymbol));
        setContractSupply(totalSupply);
        const accounts = await web3.eth.getAccounts();
        setCurrentAdd(accounts[0]);
        // console.log(accounts[0], "accounts");
        // -------------------------------------------------
        const weth_balance = await WETHContract.methods
          .balanceOf(accounts[0])
          .call();
        setEthTokenBalance(weth_balance, "ETH BAlance.....");
        // -------------------------------------------------
        const TRD = await CHAMPSContract.methods
          .getTotalDividendsDistributed()
          .call();
        setTotalRewardsDistributed(TRD);
        console.log(TRD, "Total Distributed CHAMPS Rewards");
        const balan = await CHAMPSContract.methods
          .balanceOf(accounts[0])
          .call();
        console.log(balan, "CHAMPS Balance........");
        setBalance(balan);
        // console.log(balance, "balance");
        // const dividend_balance = await CHAMPSContract.methods
        //   .getAccountDividendsInfo(accounts[0])
        //   .call();
        // // console.log(dividend_balance);
        // setUserWalletDividendsInfo(dividend_balance);
        let claimReward = await CHAMPSContract.methods
          .dividendTokenBalanceOf(accounts[0])
          .call();
        console.log(claimReward, "Claimable ETH Rewards");
        // setclaimRewards(web3.utils.fromWei(claimRewards));
        setClaimRewards(web3.utils.fromWei(claimReward));
        // showRewards
        // console.log(web3.utils.fromWei(claimReward), "claimRewards");
        // let showRewards = await CHAMPSContract.methods
        //   .withdrawableDividendOf(accounts[0])
        //   .call();
        // // setshowRewards(web3.utils.fromWei(showRewards));
        // setShowRewards(web3.utils.fromWei(showRewards));
        // console.log(claimrewards, showRewards, "showRewards");
        // console.s(tokenName, Symbol, "Symbol");
      } else {
        connectWallet();
        switch (chainId) {
          case 1:
            // getData(accounts[0], 2);
            console.log("This is mainnet");
            break;
          case 2:
            alert("This is the deprecated modern test network.");
            break;
          case 42161:
            alert("This is the binance main network.");
            break;
          default:
            // getData(accounts[0], 1);
            return alert("This is an unknown network.");
        }
        console.log("Please select the binance smart chain network");
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  const connectWallet = async () => {
    if (!walletConnected) {
      try {
        if (window.ethereum) {
          provider = await web3Modal.connect();
          setProvider(provider);

          // Subscribe to accounts change
          provider.on("accountsChanged", (accounts) => {
            // console.log(accounts);
            fetchAccountData();
          });
          //alert('provider chain changed')
          // Subscribe to chainId change
          provider.on("chainChanged", (chainId) => {
            // console.log(chainId);
            fetchAccountData();
          });
          //alert('provider network changed')
          //Subscribe to networkId change
          provider.on("networkChanged", (networkId) => {
            if (networkId !== 42161) {
              fetchAccountData();
            } else {
              alert("change to arbitrum test network");
            }
          });
          setWalletConnected(true);
          toast.success("Wallet connected");
          await refreshAccountData();
        }
      } catch (e) {
        console.log("Could not get a wallet connection", e);
        return;
      }
    } else {
      setWalletConnected(!walletConnected);
      await Disconnect();
    }
  };

  async function handleClaim() {
    if (typeof window.ethereum === "undefined") {
      console.log("Please install the MetaMask");
      toast.error("Please install the MetaMask");
    } else {
      if (walletConnected) {
        let web3 = new Web3(provider);

        const chainId = await web3.eth.getChainId();
        if (chainId === 42161) {
          const CHAMPSContract = new web3.eth.Contract(
            contractABI,
            smartContractAddress
          );
          const accounts = await web3.eth.getAccounts();
          console.log(accounts);
          // const claimRes = await CHAMPSContract.methods
          //   .claim()
          //   .send({ from: accounts[0] });
          // if (claimRes) {
          //   await refreshAccountData();
          // }
          // Estimate gas cost for the transaction
          const gas = await CHAMPSContract.methods
            .claimRewards()
            .estimateGas({ from: accounts[0] });
          const claimRes = await CHAMPSContract.methods.claimRewards().send({
            from: accounts[0],
            gas: gas,
          });

          if (claimRes) {
            await refreshAccountData();
            console.log(claimRes, "claimRes");
          }
          console.log(claimRes, "claimRes");
        } else {
          // alert("Please select the Binance Smart Chain Network");
          toast.error("Please select the Binance Smart Chain Network");
        }
      } else {
        console.log("Please connect wallet");
        toast.error("Please connect wallet");
      }
    }
  }

  // console.log(ethTokenBalance, "balance balance");

  return (
    // <div>
    //   <div id="root">
    //     <NavBar
    //       connectWallet={() => connectWallet()}
    //       walletConnected={walletConnected}
    //     />

    //     <section>
    //       <aside className="sidebar">
    //         <ul>
    //           <li>
    //             <a
    //               style={{
    //                 borderLeft: "3px solid #ddd!important",
    //                 background: "#212530",
    //               }}
    //               href="/"
    //             >
    //               <i>
    //                 <svg
    //                   viewBox="0 0 24 24"
    //                   role="presentation"
    //                   className="svg1"
    //                 >
    //                   <path
    //                     d="M12,16A3,3 0 0,1 9,13C9,11.88 9.61,10.9 10.5,10.39L20.21,4.77L14.68,14.35C14.18,15.33 13.17,16 12,16M12,3C13.81,3 15.5,3.5 16.97,4.32L14.87,5.53C14,5.19 13,5 12,5A8,8 0 0,0 4,13C4,15.21 4.89,17.21 6.34,18.65H6.35C6.74,19.04 6.74,19.67 6.35,20.06C5.96,20.45 5.32,20.45 4.93,20.07V20.07C3.12,18.26 2,15.76 2,13A10,10 0 0,1 12,3M22,13C22,15.76 20.88,18.26 19.07,20.07V20.07C18.68,20.45 18.05,20.45 17.66,20.06C17.27,19.67 17.27,19.04 17.66,18.65V18.65C19.11,17.2 20,15.21 20,13C20,12 19.81,11 19.46,10.1L20.67,8C21.5,9.5 22,11.18 22,13Z"
    //                     style={{ fill: "rgb(0, 210, 91)" }}
    //                   />
    //                 </svg>
    //               </i>
    //               <span> Overview </span>
    //             </a>
    //           </li>

    //           <dl>
    //             <dt className="menu-social">
    //               <div style={{ marginTop: "30px", marginLeft: "10px" }}>
    //                 <a href="https://discord.gg/usquyPnE">
    //                   <svg
    //                     xmlns="http://www.w3.org/2000/svg"
    //                     width={16}
    //                     height={16}
    //                     fill="currentColor"
    //                     className="bi bi-discord"
    //                     viewBox="0 0 16 16"
    //                   >
    //                     <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
    //                   </svg>
    //                 </a>
    //               </div>

    //               <div style={{ marginTop: "30px", marginLeft: "10px" }}>
    //                 <a href="https://twitter.com/cryptochamps_io?s=21">
    //                   <svg
    //                     xmlns="http://www.w3.org/2000/svg"
    //                     width={16}
    //                     height={16}
    //                     fill="currentColor"
    //                     className="bi bi-twitter"
    //                     viewBox="0 0 16 16"
    //                   >
    //                     <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
    //                   </svg>
    //                 </a>
    //               </div>
    //               <div style={{ marginTop: "30px", marginLeft: "10px" }}>
    //                 <a href="https://t.me/CryptoChampsIO">
    //                   <svg
    //                     xmlns="http://www.w3.org/2000/svg"
    //                     width={16}
    //                     height={16}
    //                     fill="currentColor"
    //                     className="bi bi-telegram"
    //                     viewBox="0 0 16 16"
    //                   >
    //                     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
    //                   </svg>
    //                 </a>
    //               </div>
    //             </dt>
    //           </dl>
    //         </ul>
    //       </aside>
    //       <div className="dashboard">
    //         <div className="flex stats">
    //           <div className="box stat">
    //             <section>
    //               <h4>CHAMPS Balance</h4>
    //               <label style={{ color: "rgb(108, 114, 147)" }}>
    //                 <span className="account-balance">
    //                   {" "}
    //                   <span>
    //                     {/* {Number(balance / Math.pow(10, 18)).toFixed(2) +
    //                       " CHAMPS"} */}
    //                     {balance
    //                       ? Number(balance / Math.pow(10, 18)).toFixed(2)
    //                       : "0.00"}
    //                   </span>
    //                 </span>
    //               </label>
    //               {/* <sub>
    //                 ≈ <span className="account-balance">$0.00</span>
    //               </sub> */}
    //             </section>
    //             <svg viewBox="0 0 24 24" role="presentation" className="svg2">
    //               <path
    //                 d="M5,3C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V16.72C21.59,16.37 22,15.74 22,15V9C22,8.26 21.59,7.63 21,7.28V5A2,2 0 0,0 19,3H5M5,5H19V7H13A2,2 0 0,0 11,9V15A2,2 0 0,0 13,17H19V19H5V5M13,9H20V15H13V9M16,10.5A1.5,1.5 0 0,0 14.5,12A1.5,1.5 0 0,0 16,13.5A1.5,1.5 0 0,0 17.5,12A1.5,1.5 0 0,0 16,10.5Z"
    //                 style={{ fill: "rgb(0, 210, 91)" }}
    //               />
    //             </svg>
    //           </div>
    //           <div className="box stat">
    //             <section>
    //               <h4>Claimable ETH Rewards</h4>
    //               <label style={{ color: "rgb(108, 114, 147)" }}>
    //                 <span className="account-balance">
    //                   {Number(claimrewards / Math.pow(10, 18)).toFixed(2) +
    //                     " ETH"}
    //                 </span>
    //               </label>
    //               {/* <sub>
    //                 ≈ <span className="account-balance">$0.0000</span>
    //               </sub> */}
    //             </section>
    //             <div className="buy">
    //               <button
    //                 onClick={() => handleClaim()}
    //                 style={{
    //                   cursor: "pointer",
    //                   padding: 6,
    //                   background:
    //                     "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
    //                   color: "white",
    //                   border: 0,
    //                   borderRadius: "5px",
    //                 }}
    //               >
    //                 CLAIM
    //               </button>
    //             </div>
    //           </div>
    //           <div className="box stat">
    //             <section>
    //               <h4>Total ETH Rewards</h4>
    //               <label
    //                 className="metric"
    //                 style={{ color: "rgb(108, 114, 147)" }}
    //               >
    //                 <span className="account-balance">
    //                   {/* {Number(
    //                     totalRewardsDistributed / Math.pow(10, 18)
    //                   ).toFixed(2)}{" "} */}
    //                   {Number(totalRewardsDistributed).toFixed(2)} ETH
    //                 </span>
    //               </label>
    //               {/* <sub>
    //                 ≈
    //                 <span className="account-balance">
    //                   <p>$0.00</p>
    //                 </span>
    //               </sub> */}
    //             </section>
    //             <img
    //               src={ethereum}
    //               alt=""
    //               style={{ height: "70px", width: "70px" }}
    //             />
    //           </div>
    //         </div>

    //         <div className="flex">
    //           <div style={{ marginTop: "-0.2px" }}>
    //             <div
    //               className="box market responsive-box"
    //               style={{ marginBottom: "10px" }}
    //             >
    //               <h2>Search Wallet Details</h2>

    //               <input
    //                 type="text"
    //                 onChange={(e) => setUserWallet(e.target.value)}
    //                 className="form-control"
    //                 placeholder=" Input your wallet address"
    //               />
    //               <div className="buy">
    //                 <a
    //                   onClick={() => init()}
    //                   style={{
    //                     cursor: "pointer",
    //                     padding: 6,
    //                     backgroundColor: "rgb(84, 21, 207)",
    //                   }}
    //                 >
    //                   SUBMIT
    //                 </a>
    //               </div>
    //             </div>
    //             <BalanceCard
    //               ethTokenBalance={ethTokenBalance}
    //               balance={balance}
    //               claimrewards={claimrewards}
    //               currentAdd={CurrentAdd}
    //               totalRewardsDistributed={totalRewardsDistributed}
    //             />

    //             <div>
    //               <ContractSupplyCard
    //                 contractSupply={contractSupply}
    //                 contractSymbol={contractSymbol}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </div>
    <body style={{ height: "100vh", overflowY: "scroll" }}>
      <Toaster />
      <Container maxWidth="xl">
        {" "}
        <Header
          connectWallet={() => connectWallet()}
          walletConnected={walletConnected}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column", sm: "column" },
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            marginTop: 5,
            mx: {
              xs: 0,
              md: 4,
            },
          }}
        >
          <ChampsBalanceCard balance={balance} />
          <ClaimEthCard
            onClick={() => handleClaim()}
            claimrewards={claimrewards}
          />
          <TotalEthReward totalRewardsDistributed={totalRewardsDistributed} />
        </Box>
        <Stack
          sx={{
            marginTop: "30px",
            marginBottom: "40px",
            paddingX: { md: 4, xs: 0 },
            maxWidth: "1400px",
          }}
        >
          <Grid container spacing={2} maxWidth={"1400px"} maxHeight={"500px"}>
            <Grid item xs={12} md={6} lg={4}>
              <Tokenomics />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SearchCard
                onChange={(e) => setUserWallet(e.target.value)}
                onClick={() => init()}
              />
              <DetailsCard
                contractSupply={contractSupply}
                contractSymbol={contractSymbol}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <YourBalance
                ethTokenBalance={ethTokenBalance}
                balance={balance}
                claimrewards={claimrewards}
                currentAdd={CurrentAdd}
                totalRewardsDistributed={totalRewardsDistributed}
              />
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <Footer />
    </body>
  );
}

export default App;
