// import { useTheme } from "@emotion/react";
import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  //   const theme = useTheme();
  //   const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  //   const zIndex = 1;

  return (
    <footer
      style={{
        // background:
        //   "linear-gradient(109.59deg, #11B1F4 -32.74%, #0F103C 127.35%)",
        background:
          "linear-gradient(109.59deg, #0F103C 0%, rgba(17, 177, 244, 0.5) 50%, #0F103C 100%)",
      }}
    >
      {" "}
      <Box sx={{ width: "100%" }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={{ xs: 4, md: 2 }}
          // padding={4}
          paddingY={{ xs: 2, sm: 4, md: 5 }}
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={"center"}
          marginTop={{ xs: 1, md: 8 }}
          width={"100%"}
          // marginBottom={{ md: 4, xs: 0 }}
        >
          {/* <Box sx={{ zIndex: zIndex - 1 }} className="white-footer-gradient" /> */}
          <Box
            display={"flex"}
            // gap={6}
            sx={{
              width: { xs: "80%", sm: "400px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Link
              to={"/terms-and-condition"}
              style={{ textDecoration: "none" }}
            >
              <Typography
                // fontSize={mobile ? 10 : 16}
                fontFamily={"Space Grotesk"}
                fontWeight={400}
                // zIndex={zIndex + 1}
                sx={{
                  color: "#FFFFFF",
                  "&:hover": { color: "#10ADEF", cursor: "pointer" },
                  cursor: "pointer",
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                Terms & Agreements
              </Typography>
            </Link>

            <Link to={"/privacy-policy"} style={{ textDecoration: "none" }}>
              <Typography
                // fontSize={mobile ? 10 : 16}
                fontFamily={"Space Grotesk"}
                fontWeight={400}
                // zIndex={zIndex + 10}
                sx={{
                  color: "#FFFFFF",
                  "&:hover": { color: "#10ADEF" },
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                Privacy Policy
              </Typography>
            </Link>
          </Box>

          <Typography
            // fontSize={mobile ? 8 : 16}
            fontFamily={"Space Grotesk"}
            fontWeight={400}
            marginRight={{ md: 5, xs: 0 }}
            sx={{
              color: "#FFFFFF",
            }}
          >
            {`@${new Date().getFullYear()} CHAMPS All Rights Reserved`}
          </Typography>
        </Stack>
      </Box>
    </footer>
  );
};

export default Footer;
