import React from "react";
import "./header.styles.css";
import { Box, Typography, useTheme } from "@mui/material";
import { RxDiscordLogo } from "react-icons/rx";
import { PiTelegramLogoLight } from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";
import { BiBookAlt } from "react-icons/bi";
import styled from "@emotion/styled";

const Header = ({ connectWallet, walletConnected }) => {
  return (
    <div className="header-container">
      <div>
        <img className="img" src={"/Images/chmaps-logo.png"} alt="logo" />
      </div>
      <Typography
        variant="caption"
        sx={{
          fontSize: { xs: "6px", sm: "16px" },
        }}
      >
        Overview
      </Typography>

      {/* ALL ICONS */}

      <div className="header-right">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            color: "white",
            marginRight: 0,
            // marginTop: 2,
          }}
        >
          <a
            href="https://twitter.com/cryptochamps_io?s=21"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Box
              // padding={0.8}
              className="social-icon-Box"
              sx={{
                borderRadius: "50px",
                border: 1,
                borderColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#11B1F4",
                  background: "transparent",
                  borderColor: "#11B1F4",
                },
                cursor: "pointer",
              }}
            >
              <RiTwitterXLine className="social-icon" />
            </Box>
          </a>

          <a
            href={"https://discord.gg/aCAxTKUArF"}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              // padding={0.8}
              className="social-icon-Box"
              sx={{
                borderRadius: "50px",
                border: 1,
                borderColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#11B1F4",
                  background: "transparent",
                  borderColor: "#11B1F4",
                },
                cursor: "pointer",
              }}
            >
              <RxDiscordLogo className="social-icon" />
            </Box>
          </a>

          <a
            href="https://t.me/CryptoChampsx"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              // padding={0.8}
              className="social-icon-Box"
              sx={{
                borderRadius: "50px",
                border: 1,
                borderColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#11B1F4",
                  background: "transparent",
                  borderColor: "#11B1F4",
                },
                cursor: "pointer",
              }}
            >
              <PiTelegramLogoLight className="social-icon" />
            </Box>
          </a>
        </div>

        <button onClick={connectWallet} className="connect-wallet-button">
          {" "}
          {walletConnected ? "Disconnect wallet " : "Connect wallet"}
        </button>
      </div>
    </div>
  );
};

export default Header;
